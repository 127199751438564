import './bootstrap'
import '../css/app.css'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { createPinia } from 'pinia'
import { i18nVue } from 'laravel-vue-i18n'
import { bus } from './bus'
import Notifications from 'notiwind'
import money from 'v-money3'
import datePlugin from './Plugins/date.js'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Popper from 'vue3-popper'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

createInertiaApp({
    progress: {
        color: '#0D0809',
    },
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue)
            .use(pinia)
            .use(i18nVue, {
                resolve: (lang) => {
                    const langs = import.meta.glob('../../lang/*.json', { eager: true })
                    return langs[`../../lang/${lang}.json`].default
                },
            })
            .component('Popper', Popper)
            .use(Notifications)
            .use(datePlugin)
            .use(money)

        app.config.globalProperties.bus = bus

        return app.mount(el)
    },
})
